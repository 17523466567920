import { useEffect, useState } from 'react';
import axios from 'axios';
import millify from 'millify';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Footer } from '../components/Footer';
import { HeaderLogged } from '../components/HeaderLogged';
import { WalletSelection } from '../components/WalletSelection';
import { ExchangeModal } from '../components/ExchangeModal';

export function Exchange() {
  const [currencies, setCurrencies] = useState([]);
  const [currentSlice, setCurrentSlice] = useState(200);
  const [currentCoin, setCurrentCoin] = useState({ coinId: 0, price: 0 });
  const [loading, setLoading] = useState(true);
  const [exchangeOpen, setExchangeOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [marketPrice, setMarketPrice] = useState(true);
  const [tradePrice, setTradePrice] = useState('0');

  const filteredCurrencies = currencies.filter((e) => e.name.toLowerCase().includes(searchTerm))
    .slice(0, currentSlice);

  const autoFetch = () => {
    let scrollIsFetching = false;
    window.addEventListener('scroll', async () => {
      if ((window.innerHeight + window.scrollY) >= document
        .body.scrollHeight - 400 && !searchTerm) {
        if (scrollIsFetching) return;
        scrollIsFetching = true;
        setCurrentSlice(currentSlice + 200);
        scrollIsFetching = false;
      }
    });
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    if (searchTerm) {
      return setCurrentSlice(20);
    } return setCurrentSlice(200);
  };

  useEffect(() => {
    autoFetch();
  }, [currentSlice]);

  function someSpread() {
    const min = -0.004;
    const max = -0.001;
    return Math.random() * (max - min) + min;
  }

  const handleClose = () => {
    setMarketPrice(true);
    setTradePrice(0);
    setExchangeOpen(false);
  };

  const handleOpen = (coinId, price, name, symbol, slug) => {
    setCurrentCoin({
      coinId, price: (price + (price * someSpread())), name, symbol, slug,
    });
    setExchangeOpen(true);
  };

  useEffect(() => {
    axios.get('https://api.cryptocricket.com/cmc/all')
      .then((response) => {
        setCurrencies(response.data.data);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          <h1>Exchange</h1>
          <WalletSelection />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={handleSearchTerm}
          />
        </Box>
        <br />
        {loading && (
        <Box justifyContent="center" sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        )}
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Symbol</th>
              <th>Price</th>
              <th>Volume</th>
              <th>24h</th>
              <th>7d</th>
              <th>30d</th>
              <th>90d</th>
              <th>Exchange</th>
            </tr>
          </thead>
          <tbody>
            {filteredCurrencies.slice(0, currentSlice).map((currency) => (
              <tr key={currency.id}>

                <td className="currency">{currency.name}</td>
                <td className="symbol">{currency.symbol}</td>
                <td className="price">
                  $
                  {(currency.quote.USD.price).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </td>
                <td className="volume">
                  $
                  {millify(currency.quote.USD.volume_24h, { precision: 3 })}
                </td>
                <td className={currency.quote.USD.percent_change_24h > 0 ? 'change' : 'change-down'}>
                  {currency.quote.USD.percent_change_24h.toFixed(2)}
                  %
                </td>
                <td className={currency.quote.USD.percent_change_7d >= 0 ? 'change' : 'change-down'}>
                  {currency.quote.USD.percent_change_7d.toFixed(2)}
                  %
                </td>
                <td className={currency.quote.USD.percent_change_30d >= 0 ? 'change' : 'change-down'}>
                  {currency.quote.USD.percent_change_30d.toFixed(2)}
                  %
                </td>
                <td className={currency.quote.USD.percent_change_90d >= 0 ? 'change' : 'change-down'}>
                  {currency.quote.USD.percent_change_90d.toFixed(2)}
                  %
                </td>
                <td>
                  <button
                    className="exchange-btn"
                    type="button"
                    onClick={() => handleOpen(
                      currency.id,
                      currency.quote.USD.price,
                      currency.name,
                      currency.symbol,
                      currency.slug,
                    )}
                    disabled={currency.id === 825}
                  >
                    Exchange
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ExchangeModal
          open={exchangeOpen}
          handleClose={handleClose}
          currentCoin={currentCoin}
          setMarketPrice={setMarketPrice}
          marketPrice={marketPrice}
          tradePrice={tradePrice}
          setTradePrice={setTradePrice}
        />
      </div>
      <Footer />
    </div>
  );
}
