import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function ExchangeStockModal(props) {
  const {
    open, handleClose, currentStock, marketPrice, setMarketPrice,
    tradePrice, setTradePrice,
  } = props;
  const [isBuying, setIsBuying] = useState(false);
  const [isSelling, setIsSelling] = useState(false);
  const [usdAmount, setUsdAmount] = useState(10);
  const [walletDetails, setWalletDetails] = useState([]);
  const [stockWalletDetails, setStockWalletDetails] = useState([]);
  const selectedWallet = useSelector((state) => state.selectedWallet);

  const changePrice = (e) => {
    console.log('changePrice');
    setTradePrice(e.target.value);
    setMarketPrice(false);
    if (+e.target.value === currentStock.stockPrice) {
      setMarketPrice(true);
    }
  };

  const handleBuy = async () => {
    const available = walletDetails.find((coin) => coin.coinId === 825);
    if (available.coinAmount < usdAmount) {
      alert('Insufficient balance');
      return;
    }
    setIsBuying(true);
    await axios.post('https://api.cryptocricket.win/exchange/buy-stock', {
      stockSymbol: currentStock.stockSymbol,
      stockName: currentStock.stockName,
      walletId: selectedWallet,
      usdAmount,
      stockPrice: marketPrice ? currentStock.stockPrice : tradePrice,
      marketPrice,
    });
    setIsBuying(false);
    alert('Transaction Successful');
    handleClose();
  };

  useEffect(() => {
    if (!isBuying) {
      axios.post('https://api.cryptocricket.win/wallet/coins-by-wallet/', {
        walletId: selectedWallet,
      })
        .then((response) => {
          setWalletDetails(response.data);
        });
    }
  }, [selectedWallet, isBuying]);

  const handleSell = async () => {
    setIsSelling(true);
    const isAvailable = stockWalletDetails.find((e) => e.stockSymbol === currentStock.stockSymbol);
    if (isAvailable) {
      const usdAvailable = currentStock.stockPrice * isAvailable.stockAmount;
      if (usdAvailable < usdAmount) {
        alert('Insufficient Balance');
        setIsSelling(false);
        handleClose();
        return;
      }
      await axios.post('https://api.cryptocricket.win/exchange/sell-stock', {
        stockSymbol: currentStock.stockSymbol,
        stockName: currentStock.stockName,
        walletId: selectedWallet,
        usdAmount,
        stockPrice: marketPrice ? currentStock.stockPrice : tradePrice,
        marketPrice,
      });
      setIsSelling(false);
      alert('Transaction Successful');
      handleClose();
    } else {
      alert('You do not have this asset in your wallet');
      setIsSelling(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (!isBuying && !isSelling) {
      axios.post('https://api.cryptocricket.win/wallet/coins-by-wallet/', {
        walletId: selectedWallet,
      })
        .then((response) => {
          setWalletDetails(response.data);
        });
      axios.post('https://api.cryptocricket.win/stocks-by-wallet/', {
        walletId: selectedWallet,
      })
        .then((response) => {
          setStockWalletDetails(response.data);
        });
    }
  }, [selectedWallet, isBuying, isSelling]);

  useEffect(() => {
    console.log(marketPrice);
  }, [marketPrice]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {currentStock.stockName}
          {' '}
          |
          {' '}
          {currentStock.stockSymbol}
          <br />
          <a href={`https://companiesmarketcap.com/${currentStock?.stockName?.replace(/\s/g, '-').toLowerCase()}/marketcap/`} target="_blank" rel="noreferrer">C. Market Cap</a>
          {' '}
          |
          {' '}
          <a href={`https://finance.yahoo.com/quote/${currentStock.stockSymbol}`} target="_blank" rel="noreferrer">Yahoo Finance</a>
          {' '}
          |
          {' '}
          <a href={`https://www.google.com/search?q=${currentStock.stockSymbol}+stock`} target="_blank" rel="noreferrer">Google Finance</a>
          {' '}
          <a href={`https://www.marketwatch.com/investing/stock/${currentStock.stockSymbol}`} target="_blank" rel="noreferrer">Market Watch</a>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          No description available.
        </Typography>

        <Stack justifyContent="center" marginTop={2} spacing={2} direction="row">

          <TextField
            id="standard-helperText"
            helperText="Price (USD)"
            variant="standard"
            value={marketPrice ? currentStock.stockPrice : tradePrice}
            onChange={changePrice}
          />

          <TextField
            id="standard-helperText"
            value={usdAmount}
            onChange={(e) => setUsdAmount(e.target.value)}
            helperText="Amount (USD)"
            variant="standard"
          />

          <Button
            variant="contained"
            color="success"
            onClick={handleBuy}
          >
            {isBuying ? 'Buying...' : 'Buy'}
          </Button>
          <Button variant="contained" color="error" onClick={handleSell}>{isSelling ? 'Selling...' : 'Sell'}</Button>
        </Stack>
      </Box>
    </Modal>
  );
}

ExchangeStockModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  marketPrice: PropTypes.bool.isRequired,
  setMarketPrice: PropTypes.func.isRequired,
  tradePrice: PropTypes.number.isRequired,
  setTradePrice: PropTypes.func.isRequired,
  currentStock: PropTypes.shape({
    stockName: PropTypes.string,
    stockSymbol: PropTypes.string,
    stockPrice: PropTypes.number,
  }).isRequired,
};
