import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import { HeaderLogged } from '../components/HeaderLogged';
import { Footer } from '../components/Footer';
import { WalletSelection } from '../components/WalletSelection';

export function Widthdraw() {
  const selectedWallet = useSelector((state) => state.selectedWallet);
  const [amount, setAmount] = useState(10);
  const [destination, setDestination] = useState('Regions Bank | Orlando/FL');
  const [loading, setLoading] = useState(false);
  const [widthdrawHistory, setWidthdrawHistory] = useState([]);

  const handleDestination = (event) => {
    setDestination(event.target.value);
  };

  const newWithdraw = async () => {
    if (selectedWallet === 22) return alert('Please select a wallet');
    setLoading(true);
    await axios.post('https://api.cryptocricket.win/deposit/new-withdraw/', {
      userId: localStorage.getItem('userId'),
      walletId: selectedWallet,
      destination,
      withdrawAmount: amount,
    });
    return setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const result = await axios.post('https://api.cryptocricket.win/deposit/get-withdraws/', {
        userId: localStorage.getItem('userId'),
      });
      setWidthdrawHistory(result.data);
    })();
  }, [loading]);

  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          <h1>Withdraw</h1>
          <WalletSelection />
        </Box>
        <Stack justifyContent="center" marginTop={5} spacing={2} direction="row">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Destination</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={destination}
              onChange={handleDestination}
            >
              <FormControlLabel
                value="Regions Bank | Orlando / FL"
                control={<Radio />}
                label="Regions Bank | Orlando / FL"
              />
              <FormControlLabel
                value="Binance Wallet"
                control={<Radio />}
                label="Binance Wallet"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack justifyContent="center" marginTop={1} marginBottom={5} spacing={2} direction="row">
          <TextField
            id="standard-helperText"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            padding={10}
            helperText="Amount (USDT) Maximum 375 USDT"
            variant="standard"
          />
          <Button
            variant="contained"
            color="success"
            onClick={newWithdraw}
          >
            {loading ? 'Loading...' : 'Withdraw'}
          </Button>
        </Stack>

        <br />
        <table>
          <thead>
            <tr>
              <th>Wallet Name</th>
              <th>Bank</th>
              <th>Withdraw Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {widthdrawHistory.map((e) => (
              <tr key={uuidv4()}>
                <td className="currency">{e.walletName}</td>
                <td className="bank">
                  {e.withdrawBank}
                </td>
                <td className="symbol">
                  $
                  {e.withdrawAmount}
                </td>
                <td className="price">{e.withdrawStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {widthdrawHistory.length === 0 && <p>Historical data are not available.</p>}
      </div>
      <Footer />
    </div>
  );
}
