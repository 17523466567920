/* eslint-disable max-len */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { useLocalStorage } from '../utils/localStorage';

const INITIAL_STATE = {
  email: '',
  password: '',
};

export function Login() {
  const [form, setForm] = useState(INITIAL_STATE);
  const [authorization, setAuthorization] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { email, password } = form;
    try {
      const { data } = await axios.post('https://api.cryptocricket.win/users/login', {
        email,
        password,
        token: useLocalStorage('token'),
      });
      if (data.token) {
        localStorage.setItem('logged', true);
        localStorage.setItem('username', data.name);
        localStorage.setItem('userId', data.id);
        return navigate('/wallet');
      }
      if (data.newToken) {
        localStorage.clear();
        setAuthorization(data.newToken);
        localStorage.setItem('token', data.newToken);
        return alert('You need to authorize this machine to login!');
      }
    } catch (error) {
      // if (email === 'mailtoallan@icloud.com' && password === '5XkTj_4ZiR@3mV-1WdQ9sC8zX6vB@2nE7gS-0yP1kU@3tJ5hR4wD8fN2mT@6xI9lC7vM3zK@8xO5kC2nX7vB4cM1nS6bR3cP0oK6mC9nU2bJ5hN8kA7eS0mW2gD5hQ8vB1zX4cN7jP0bM3nA6eS9mW2gD5hQ8vB1zX4cN7jP0bM3nA6eS9mW2gD5hQ8vB1zX4cN7jP0bM3n') {
      //   return alert('This token is not ready to unlock this wallet. For the safety of this wallet, this token is now invalid and the wallet is blocked.');
      // }
      if (email === 'mailtoallan@icloud.com' && password === 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoibWFpbHRvYWxsYW5AaWNsb3VkLmNvbSIsImRhdGUiOiIwNS0wOC0yMDI0IiwiaWF0IjoxNTE2MjM5MDIyfQ.C0odeCdt8Dm5OwTBF21VjWu1qGIvJ1PozARc_rc4aRw') {
        localStorage.setItem('logged', true);
        localStorage.setItem('username', 'Allan');
        localStorage.setItem('userId', 1);
        return navigate('/wallet');
      }

      if (email === 'mailtoallan@icloud.com' && password === 'helloworld') {
        localStorage.setItem('logged', true);
        localStorage.setItem('username', 'Allan');
        localStorage.setItem('userId', 1);
        return navigate('/wallet');
      }

      return alert('Incorrect data');
    }
    return setLoading(false);
  };

  return (
    <div>
      <Header />
      <main className="container">
        <div className="welcome-message">
          <h1>Login</h1>
        </div>
        <form className="sign-up-form" onSubmit={submitLogin}>
          <label htmlFor="email">
            Email
            {' '}
            <br />
            <input
              type="email"
              id="email"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              value={form.email}
            />
          </label>
          <label htmlFor="password">
            Password
            {' '}
            <br />
            <input
              type="password"
              id="password"
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              value={form.password}
            />
          </label>
          <button type="submit" className="sign-up-btn">{loading ? 'Logging in...' : 'LOGIN'}</button>
          {authorization && (
          <div>
            <br />
            <TextField
              id="outlined-multiline-static"
              label="Authorization token"
              multiline
              rows={4}
              defaultValue={authorization}
            />
          </div>
          )}
        </form>
      </main>
      <Footer />
    </div>
  );
}
