import propTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function ExchangeModal(props) {
  const {
    open, handleClose, currentCoin, marketPrice, setMarketPrice,
    tradePrice, setTradePrice,
  } = props;
  const [coinData, setCoinData] = useState([]);
  const [isBuying, setIsBuying] = useState(false);
  const [isSelling, setIsSelling] = useState(false);
  const [walletDetails, setWalletDetails] = useState([]);
  const [usdAmount, setUsdAmount] = useState(10);
  const selectedWallet = useSelector((state) => state.selectedWallet);

  const changePrice = (e) => {
    setTradePrice(e.target.value);
    setMarketPrice(false);
    if (+e.target.value === currentCoin.price) {
      setMarketPrice(true);
    }
  };

  const handleBuy = async () => {
    const available = walletDetails.find((coin) => coin.coinId === 825);
    if (available.coinAmount < usdAmount) {
      alert('Insufficient balance');
      handleClose();
      return;
    }
    setIsBuying(true);
    await axios.post('https://api.cryptocricket.win/exchange/buy', {
      coinId: currentCoin.coinId,
      coinPrice: marketPrice ? currentCoin.price : tradePrice,
      walletId: selectedWallet,
      coinName: currentCoin.name,
      coinSymbol: currentCoin.symbol,
      usdAmount,
      marketPrice,
    });
    setIsBuying(false);
    alert('Transaction Successful');
    handleClose();
  };

  const handleSell = async () => {
    setIsSelling(true);
    const isAvailable = walletDetails.find((e) => e.coinId === currentCoin.coinId);
    if (isAvailable) {
      const usdAvailable = currentCoin.price * isAvailable.coinAmount;
      if (usdAvailable < usdAmount) {
        alert('Insufficient Balance');
        setIsSelling(false);
        handleClose();
        return;
      }
      await axios.post('https://api.cryptocricket.win/exchange/sell', {
        coinId: currentCoin.coinId,
        coinPrice: marketPrice ? currentCoin.price : tradePrice,
        coinSymbol: currentCoin.symbol,
        coinName: currentCoin.name,
        walletId: selectedWallet,
        usdAmount,
        marketPrice,
      });
      setIsSelling(false);
      alert('Transaction Successful');
      handleClose();
    } else {
      alert('You do not have this coin in your wallet');
      setIsSelling(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (!isBuying && !isSelling) {
      axios.post('https://api.cryptocricket.win/wallet/coins-by-wallet/', {
        walletId: selectedWallet,
      })
        .then((response) => {
          setWalletDetails(response.data);
        });
    }
  }, [selectedWallet, isBuying, isSelling]);

  useEffect(() => {
    if (currentCoin.coinId) {
      axios.get(`https://api.cryptocricket.win/cmc/coin/${currentCoin.coinId}`)
        .then((response) => {
          setCoinData(response.data);
        });
    }
  }, [currentCoin.coinId]);

  useEffect(() => {
    console.log(tradePrice);
    console.log(marketPrice);
  }, [tradePrice, marketPrice]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {coinData?.data?.[currentCoin.coinId]?.name}
          {' '}
          |
          {' '}
          <a href={coinData?.data?.[currentCoin.coinId]?.urls?.website[0]} target="_blank" rel="noreferrer">{coinData?.data?.[currentCoin.coinId]?.urls?.website[0]}</a>
          <br />
          CMC:
          {' '}
          <a href={`https://coinmarketcap.com/currencies/${currentCoin.slug}/`} target="_blank" rel="noreferrer">CoinMarketCap</a>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {coinData?.data?.[currentCoin.coinId]?.description}
        </Typography>

        <Stack justifyContent="center" marginTop={2} spacing={2} direction="row">

          <TextField
            id="standard-helperText"
            helperText="Price (USD)"
            variant="standard"
            value={marketPrice ? currentCoin.price : tradePrice}
            onChange={changePrice}
          />

          <TextField
            id="standard-helperText"
            value={usdAmount}
            onChange={(e) => setUsdAmount(e.target.value)}
            helperText="Amount (USD)"
            variant="standard"
          />

          <Button
            variant="contained"
            color="success"
            onClick={handleBuy}
          >
            {isBuying ? 'Buying...' : 'Buy'}
          </Button>
          <Button variant="contained" color="error" onClick={handleSell}>{isSelling ? 'Selling...' : 'Sell'}</Button>
        </Stack>
      </Box>
    </Modal>
  );
}

ExchangeModal.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  currentCoin: propTypes.objectOf().isRequired,
  setMarketPrice: propTypes.func.isRequired,
  marketPrice: propTypes.bool.isRequired,
  tradePrice: propTypes.number.isRequired,
  setTradePrice: propTypes.func.isRequired,
};
