import { configureStore } from '@reduxjs/toolkit';
import storeSynchronize from 'redux-localstore';
import { signUpSlice } from './slicers/signUp';
import { selectedWalletSlice } from './slicers/walletSelection';

export const store = configureStore({
  reducer: {
    signUp: signUpSlice.reducer,
    selectedWallet: selectedWalletSlice.reducer,
  },
});

storeSynchronize(store);
