import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

export function Locked() {
  return (
    <div>
      <Header />
      <main className="container">
        <div className="wallet-container">
          <h1>Lock and stake</h1>
          <p>Your wallet is secured with lock and stake!</p>
          <br />
          <input type="text" value="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoibWFpbHRvYWxsYW5AaWNsb3VkLmNvbSIsImRhdGUiOiIwNS0wOC0yMDI0IiwiaWF0IjoxNTE2MjM5MDIyfQ.C0odeCdt8Dm5OwTBF21VjWu1qGIvJ1PozARc_rc4aRw" />
        </div>
      </main>
      <Footer />
    </div>
  );
}
