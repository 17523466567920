import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { HeaderLogged } from '../components/HeaderLogged';
import { Footer } from '../components/Footer';

export function ProcessPayment() {
  const [pendingDeposits, setPendingDeposits] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios.post('https://api.cryptocricket.win/deposit/get-pending-deposits/')
      .then((response) => {
        console.log(response.data);
        setPendingDeposits(response.data);
      });
  }, []);

  const approveDeposit = async (deposit) => {
    const { depositId, walletId, depositAmount } = deposit;
    setLoading(true);
    await axios.post('https://api.cryptocricket.win/deposit/approve-deposit/', {
      depositId,
      walletId,
      depositAmount,
    });
    setLoading(false);
  };

  return (
    <div>
      <HeaderLogged />
      <h1>Process Payment!</h1>
      <table>
        <thead>
          <tr>
            <th>Wallet Name</th>
            <th>Deposit Amount</th>
            <th>Status</th>
            <th>Approve</th>
          </tr>
        </thead>
        <tbody>
          {pendingDeposits.map((e) => (
            <tr key={uuidv4()}>
              <td className="currency">{e.walletName}</td>
              <td className="symbol">
                $
                {e.depositAmount}
              </td>
              <td className="price">{e.depositStatus}</td>
              <td className="approve">
                <button type="button" onClick={() => approveDeposit(e)}>{loading ? 'Approving...' : 'Approve'}</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pendingDeposits.length === 0 && <p>No deposits to approve.</p>}
      <Footer />
    </div>
  );
}
