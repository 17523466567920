/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocalStorage } from '../utils/localStorage';
import Logo from '../assets/png/logo2.png';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export function HeaderLogged() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [goOnline, setGoOnline] = useState(false);
  const [startOnline, setStartOnline] = useState(false);
  const [progress, setProgress] = useState(1);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.setItem('logged', false);
    navigate('/lockout');
  };

  const handleClose = () => {
    console.log('HANDLE CLOSE');
    removeToken();
    console.log('removeToken');
    setOpen(false);
  };

  const handleOpen = () => {
    if (progress < 100) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const isLogged = useLocalStorage('logged');
    if (isLogged === 'false' || isLogged === undefined || isLogged === 'empty key') {
      navigate('/login');
    }
  }, []);

  const handleLogout = () => {
    localStorage.setItem('logged', false);
    navigate('/login');
  };

  const handleContinue = () => {
    setOpen(false);
    setGoOnline(true);
  };

  const handleOnline = () => {
    setStartOnline(true);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
    }, 100);
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      setGoOnline(false);
      setStartOnline(false);
      setProgress(1);
    }
  }, [progress]);

  return (
    <header className="header-logged">
      <div className="container">
        <div className="logo">
          <Link to="/wallet">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="menu-links">
          <Link to="/wallet">Wallet</Link>
          <Link to="/stake">Stake</Link>
          <Link to="/exchange">Exchange</Link>
          <Link to="/stocks">Stocks</Link>
          <Link to="/withdraw">Withdraw</Link>
          <Link to="/deposit">Deposit</Link>
          <Link to="/settings">Settings</Link>
        </div>
        <div className="user">
          <div className="hello-user">
            <p>
              Howdy, @
              {useLocalStorage('username')}
            </p>
          </div>
          <div className="logout-link">
            <Link
              to="/login"
              onClick={handleLogout}
              className="logout-btn"
            >
              Logout
            </Link>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New access detected.
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have the option to lock out and stake while you are away or give the token for access.
          </Typography>

          <Stack justifyContent="center" marginTop={2} spacing={2} direction="row">

            <Button variant="contained" color="success" onClick={handleClose}>Yes</Button>
            <Button variant="contained" color="error" onClick={handleContinue}>No</Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={goOnline}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {startOnline ? 'WARNING: Waking up the wallet' : 'Your wallet is idle'}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {startOnline ? 'Keep this window open while we locate all wallets. ' : 'You want to go online and continue the process?'}
            {startOnline && 'Make sure you keep this device connected to the power source and the internet. This process may take a while.'}
          </Typography>

          {startOnline && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <CircularProgressWithLabel value={progress} />
          </Box>
          )}

          <Stack justifyContent="center" marginTop={2} spacing={2} direction="row">

            {!startOnline && (
            <>
              <Button variant="contained" color="success" onClick={handleOnline}>Yes</Button>
              <Button variant="contained" color="error" onClick={handleLogout}>No</Button>
            </>
            )}
            {startOnline && (
              <Button variant="contained" color="error" onClick={handleLogout}>Stop the process</Button>
            )}
          </Stack>
        </Box>
      </Modal>
    </header>
  );
}
