import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Footer } from '../components/Footer';
import { HeaderLogged } from '../components/HeaderLogged';
import { WalletSelection } from '../components/WalletSelection';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const stakesAvailable = [
  {
    id: 1,
    type: 'crypto',
    name: 'USDD',
    stakePeriod: 720,
    stakeAPY: 70.3,
    symbol: 'USDD',
  },
  {
    id: 2,
    type: 'crypto',
    name: 'USDD',
    stakePeriod: 360,
    stakeAPY: 30.2,
    symbol: 'USDD',
  },
  {
    id: 3,
    type: 'crypto',
    name: 'USDD',
    stakePeriod: 180,
    stakeAPY: 18.6,
    symbol: 'USDD',
  },
  {
    id: 4,
    type: 'crypto',
    name: 'USDD',
    stakePeriod: 90,
    stakeAPY: 8.7,
    symbol: 'USDD',
  },
  {
    id: 5,
    type: 'crypto',
    name: 'USDD',
    stakePeriod: 30,
    stakeAPY: 6.4,
    symbol: 'USDD',
  },
  {
    id: 6,
    type: 'crypto',
    name: 'NEXO',
    stakePeriod: 30,
    stakeAPY: 10,
    symbol: 'NEXO',
  },
  {
    id: 7,
    type: 'crypto',
    name: 'Bitcoin',
    stakePeriod: 30,
    stakeAPY: 4.2,
    symbol: 'BTC',
  },
  {
    id: 8,
    type: 'crypto',
    name: 'Bitcoin',
    stakePeriod: 90,
    stakeAPY: 5.8,
    symbol: 'BTC',
  },
  {
    id: 9,
    type: 'crypto',
    name: 'Bitcoin',
    stakePeriod: 180,
    stakeAPY: 6.6,
    symbol: 'BTC',
  },
  {
    id: 10,
    type: 'crypto',
    name: 'Bitcoin',
    stakePeriod: 360,
    stakeAPY: 7.1,
    symbol: 'BTC',
  },
  {
    id: 11,
    type: 'crypto',
    name: 'Ethereum',
    stakePeriod: 360,
    stakeAPY: 29.5,
    symbol: 'ETH',
  },
  {
    id: 12,
    type: 'crypto',
    name: 'Ethereum',
    stakePeriod: 30,
    stakeAPY: 8.2,
    symbol: 'ETH',
  },
  {
    id: 13,
    type: 'crypto',
    name: 'BNB',
    stakePeriod: 30,
    stakeAPY: 9.1,
    symbol: 'BNB',
  },
  {
    id: 15,
    type: 'crypto',
    name: 'BNB',
    stakePeriod: 90,
    stakeAPY: 12.4,
    symbol: 'BNB',
  },
  {
    id: 14,
    type: 'crypto',
    name: 'Tether USD',
    stakePeriod: 90,
    stakeAPY: 5.1,
    symbol: 'USDT',
  },
  {
    id: 16,
    type: 'crypto',
    name: 'Chiliz',
    stakePeriod: 90,
    stakeAPY: 10.5,
    symbol: 'CHZ',
  },
  {
    id: 17,
    type: 'crypto',
    name: 'Axie Infinity',
    stakePeriod: 90,
    stakeAPY: 12.3,
    symbol: 'AXS',
  },
  {
    id: 18,
    type: 'crypto',
    name: 'PAX Gold',
    stakePeriod: 360,
    stakeAPY: 14.6,
    symbol: 'PAXG',
  },
  {
    id: 20,
    type: 'crypto',
    name: 'PAX Gold',
    stakePeriod: 90,
    stakeAPY: 10.3,
    symbol: 'PAXG',
  },
  {
    id: 21,
    type: 'crypto',
    name: 'Bluzelle',
    stakePeriod: 90,
    stakeAPY: 40.8,
    symbol: 'BLZ',
  },
  {
    id: 22,
    type: 'stock',
    name: 'Apple Inc.',
    stakePeriod: 360,
    stakeAPY: 10.2,
    symbol: 'AAPL',
  },
  {
    id: 25,
    type: 'stock',
    name: 'Apple Inc.',
    stakePeriod: 90,
    stakeAPY: 7.4,
    symbol: 'AAPL',
  },
  {
    id: 36,
    type: 'stock',
    name: 'Apple Inc.',
    stakePeriod: 30,
    stakeAPY: 6.2,
    symbol: 'AAPL',
  },
  {
    id: 23,
    type: 'stock',
    name: 'Amazon.com, Inc.',
    stakePeriod: 360,
    stakeAPY: 7.5,
    symbol: 'AMZN',
  },
  {
    id: 37,
    type: 'stock',
    name: 'Amazon.com, Inc.',
    stakePeriod: 30,
    stakeAPY: 5.2,
    symbol: 'AMZN',
  },
  {
    id: 24,
    type: 'stock',
    name: 'Tesla, Inc.',
    stakePeriod: 360,
    stakeAPY: 4.9,
    symbol: 'TSLA',
  },
  {
    id: 38,
    type: 'stock',
    name: 'Tesla, Inc.',
    stakePeriod: 30,
    stakeAPY: 3.5,
    symbol: 'TSLA',
  },
  {
    id: 27,
    type: 'stock',
    name: 'Ford Motor Company',
    stakePeriod: 30,
    stakeAPY: 3.8,
    symbol: 'F',
  },
  {
    id: 28,
    type: 'stock',
    name: 'NVIDIA Corporation',
    stakePeriod: 30,
    stakeAPY: 4.1,
    symbol: 'NVDA',
  },
  {
    id: 29,
    type: 'stock',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    stakePeriod: 30,
    stakeAPY: 3.8,
    symbol: 'PBR',
  },
  {
    id: 51,
    type: 'stock',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    stakePeriod: 45,
    stakeAPY: 8.1,
    symbol: 'PBR',
  },
  {
    id: 30,
    type: 'stock',
    name: 'Petróleo Brasileiro S.A. - Petrobras',
    stakePeriod: 360,
    stakeAPY: 16.9,
    symbol: 'PBR',
  },
  {
    id: 31,
    type: 'stock',
    name: 'Bank of America Corporation',
    stakePeriod: 360,
    stakeAPY: 6.5,
    symbol: 'BAC',
  },
  {
    id: 32,
    type: 'stock',
    name: 'Alphabet Inc.',
    stakePeriod: 360,
    stakeAPY: 4.9,
    symbol: 'GOOGL',
  },
  {
    id: 33,
    type: 'stock',
    name: 'Pfizer Inc.',
    stakePeriod: 360,
    stakeAPY: 4.3,
    symbol: 'PFE',
  },
  {
    id: 35,
    type: 'stock',
    name: 'Vale S.A.',
    stakePeriod: 30,
    stakeAPY: 4.1,
    symbol: 'VALE',
  },
  {
    id: 39,
    type: 'stock',
    name: 'Shell',
    stakePeriod: 30,
    stakeAPY: 6.1,
    symbol: 'SHEL',
  },
  {
    id: 40,
    type: 'stock',
    name: 'MercadoLibre',
    stakePeriod: 30,
    stakeAPY: 7.3,
    symbol: 'MELI',
  },
  {
    id: 41,
    type: 'crypto',
    name: 'Solana',
    stakePeriod: 30,
    stakeAPY: 10.1,
    symbol: 'SOL',
  },
  {
    id: 42,
    type: 'crypto',
    name: 'Kyber Network Crystal v2',
    stakePeriod: 30,
    stakeAPY: 8.3,
    symbol: 'KNC',
  },
  {
    id: 43,
    type: 'stock',
    name: 'Bank of America',
    stakePeriod: 30,
    stakeAPY: 3.8,
    symbol: 'BAC',
  },
  {
    id: 44,
    type: 'stock',
    name: 'Booking Holdings (Booking.com)',
    stakePeriod: 30,
    stakeAPY: 10.7,
    symbol: 'BKNG',
  },
  {
    id: 45,
    type: 'stock',
    name: 'Rumo',
    stakePeriod: 30,
    stakeAPY: 7.8,
    symbol: 'RAIL3.SA',
  },
  {
    id: 46,
    type: 'stock',
    name: 'BRF',
    stakePeriod: 30,
    stakeAPY: 11.0,
    symbol: 'BRFS',
  },
  {
    id: 47,
    type: 'stock',
    name: 'CSN Mineração',
    stakePeriod: 30,
    stakeAPY: 9.7,
    symbol: 'CMIN3.SA',
  },
  {
    id: 48,
    type: 'stock',
    name: 'Vale S.A.',
    stakePeriod: 45,
    stakeAPY: 11.6,
    symbol: 'VALE',
  },
  {
    id: 49,
    type: 'stock',
    name: 'Usiminas',
    stakePeriod: 45,
    stakeAPY: 19.7,
    symbol: 'USIM5.SA',
  },
  {
    id: 50,
    type: 'stock',
    name: 'Usiminas',
    stakePeriod: 30,
    stakeAPY: 11.3,
    symbol: 'USIM5.SA',
  },
  {
    id: 52,
    type: 'stock',
    name: 'LoveSac',
    stakePeriod: 45,
    stakeAPY: 31.5,
    symbol: 'LOVE',
  },
  {
    id: 53,
    type: 'stock',
    name: 'Vale S.A.',
    stakePeriod: 60,
    stakeAPY: 27.8,
    symbol: 'VALE',
  },
];

const staking = stakesAvailable.sort((a, b) => b.stakeAPY - a.stakeAPY);

export function Stake() {
  const [currentStake, setCurrentStake] = useState({ stakeName: 0, stakeAPY: 0 });
  const [isBuying, setIsBuying] = useState(false);
  const [open, setOpen] = useState(false);
  const [walletDetails, setWalletDetails] = useState([]);
  const [stockWalletDetails, setStockWalletDetails] = useState([]);
  const [assetAmount, setAssetAmount] = useState(0);
  const selectedWallet = useSelector((state) => state.selectedWallet);

  useEffect(() => {
    if (!isBuying) {
      axios.post('https://api.cryptocricket.win/wallet/coins-by-wallet/', {
        walletId: selectedWallet,
      })
        .then((response) => {
          setWalletDetails(response.data);
        });
      axios.post('https://api.cryptocricket.win/wallet/stocks-by-wallet/', {
        walletId: selectedWallet,
      })
        .then((response) => {
          setStockWalletDetails(response.data);
        });
    }
  }, [selectedWallet, isBuying]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (stakeName, stakeSymbol, stakePeriod, stakeType, stakeAPY) => {
    setCurrentStake({
      stakeName, stakeSymbol, stakePeriod, stakeType, stakeAPY,
    });
    setOpen(true);
  };

  const handleStake = () => {
    const available = currentStake.stakeType === 'crypto'
      ? walletDetails.find((e) => e.coinName === currentStake.stakeName)
      : stockWalletDetails.find((e) => e.stockSymbol === currentStake.stakeSymbol);
    console.log(available);
    console.log(currentStake);
    if (!available) {
      alert('You do not have this asset in your wallet');
      setOpen(false);
      return;
    }
    if (available.coinAmount < assetAmount || available.stockAmount < assetAmount) {
      alert('Insufficient balance');
      setOpen(false);
      return;
    }
    setIsBuying(true);
    axios.post('https://api.cryptocricket.win/stake/new/', {
      currWallet: available.walletId,
      stakeSymbol: currentStake.stakeSymbol,
      stakeName: currentStake.stakeName,
      assetAmount: +assetAmount,
      stakeAPY: currentStake.stakeAPY,
      stakeType: currentStake.stakeType,
      stakePeriod: currentStake.stakePeriod,
    })
      .then(() => {
        alert('Stake successful');
        setIsBuying(false);
        setOpen(false);
      });
  };

  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          <h1>Stake</h1>
          <WalletSelection />
        </Box>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Symbol</th>
              <th>Type</th>
              <th>APY</th>
              <th>Period</th>
              <th>Stake</th>
            </tr>
          </thead>
          <tbody>
            {staking.map((stake) => (
              <tr key={stake.id}>

                <td className="stake">{stake.name}</td>
                <td className="symbol">{stake.symbol}</td>
                <td className="type">
                  {stake.type === 'crypto' ? 'Crypto' : 'Stock'}
                </td>
                <td className="apy">
                  {stake.stakeAPY}
                  %
                </td>
                <td className="period">
                  {stake.stakePeriod}
                </td>
                <td>
                  <button
                    className="exchange-btn"
                    type="button"
                    onClick={() => handleOpen(
                      stake.name,
                      stake.symbol,
                      stake.stakePeriod,
                      stake.type,
                      stake.stakeAPY,
                    )}
                  >
                    Stake
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Stake
              {' '}
              {currentStake.stakeName}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              APY:
              {' '}
              {currentStake.stakeAPY}
              %
            </Typography>

            <Stack justifyContent="center" marginTop={2} spacing={2} direction="row">

              <TextField
                id="standard-helperText"
                value={assetAmount}
                onChange={(e) => setAssetAmount(e.target.value)}
                helperText={`Available: ${walletDetails.find((e) => e.coinName === currentStake.stakeName)?.coinAmount || stockWalletDetails.find((e) => e.stockSymbol === currentStake.stakeSymbol)?.stockAmount || 0}`}
                variant="standard"
              />

              <Button
                variant="contained"
                color="success"
                onClick={handleStake}
              >
                {isBuying ? 'Staking...' : 'Stake'}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
