import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState(22)('reducer');

export const selectedWalletSlice = createSlice({
  name: 'selectedWallet',
  initialState,
  reducers: {
    setSelectedWallet: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setSelectedWallet } = selectedWalletSlice.actions;
