import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import millify from 'millify';
import { HeaderLogged } from '../components/HeaderLogged';
import { Footer } from '../components/Footer';
import { WalletSelection } from '../components/WalletSelection';
import { ExchangeStockModal } from '../components/ExchangeStockModal';

export function Stocks() {
  const [stocksList, setStocksList] = useState([]);
  const [currentSlice, setCurrentSlice] = useState(200);
  const [currentStock, setCurrentStock] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [marketPrice, setMarketPrice] = useState(true);
  const [tradePrice, setTradePrice] = useState('0');

  const filteredStocks = stocksList.filter((e) => e.stockName.toLowerCase().includes(searchTerm))
    .slice(0, currentSlice);

  const autoFetch = () => {
    let scrollIsFetching = false;
    window.addEventListener('scroll', async () => {
      if ((window.innerHeight + window.scrollY) >= document
        .body.scrollHeight - 400 && !searchTerm) {
        if (scrollIsFetching) return;
        scrollIsFetching = true;
        setCurrentSlice(currentSlice + 200);
        scrollIsFetching = false;
      }
    });
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    if (searchTerm) {
      return setCurrentSlice(20);
    } return setCurrentSlice(200);
  };

  const handleClose = () => {
    setMarketPrice(true);
    setTradePrice(0);
    setOpen(false);
  };

  const handleOpen = (stockCountry, stockName, stockSymbol, stockPrice, stockMarketCap) => {
    setCurrentStock({
      stockName,
      stockPrice,
      stockCountry,
      stockSymbol,
      stockMarketCap,
    });
    setOpen(true);
  };

  useEffect(() => {
    autoFetch();
  }, [currentSlice]);

  // useEffect(() => {
  //   if (currentStock.stockName) {
  //     console.log('name changed');
  //   }
  // }, [currentStock.stockName]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const result = await axios.get('https://api.cryptocricket.win/stocks/all');
      setStocksList(result.data);
    })();
    setLoading(false);
  }, []);

  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          <h1>Stocks</h1>
          <WalletSelection />
        </Box>
        <Box sx={{ width: '100%' }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={handleSearchTerm}
          />
        </Box>
        <br />
        {loading && (
        <Box justifyContent="center" sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        )}

        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Symbol</th>
              <th>Price</th>
              <th>Market Cap</th>
              <th>Country</th>
              <th>Exchange</th>
            </tr>
          </thead>
          <tbody>
            {filteredStocks.slice(0, currentSlice).map((e) => (
              <tr key={e.stockName}>

                <td className="stock-name">{e.stockName}</td>
                <td className="stock-symbol">{e.stockSymbol}</td>
                <td className="price">
                  $
                  {(e.stockPrice).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </td>
                <td className="m-cap">
                  $
                  {millify(e.stockMarketCap, { precision: 3 })}
                </td>
                <td className="country">
                  {e.stockCountry}
                </td>
                <td>
                  <button
                    className="exchange-btn"
                    type="button"
                    onClick={() => handleOpen(
                      e.stockCountry,
                      e.stockName,
                      e.stockSymbol,
                      e.stockPrice,
                      e.stockMarketCap,
                    )}
                  >
                    Exchange
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ExchangeStockModal
          open={open}
          handleClose={handleClose}
          currentStock={currentStock}
          setMarketPrice={setMarketPrice}
          marketPrice={marketPrice}
          tradePrice={tradePrice}
          setTradePrice={setTradePrice}
        />
      </div>
      <Footer />
    </div>
  );
}
