import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
// import axios from 'axios';
import { useEffect, useState } from 'react';
import { setSelectedWallet } from '../redux/slicers/walletSelection';

export function WalletSelection() {
  const dispatch = useDispatch();
  const [walletList, setWalletList] = useState([]);
  const selectedWallet = useSelector((state) => state.selectedWallet);

  useEffect(() => {
    // axios.post('https://api.cryptocricket.win/wallet/get-wallets/', {
    //   userId: localStorage.getItem('userId'),
    // })
    //   .then((response) => {
    //     setWalletList(response.data);
    //   });
    setWalletList(
      [{ walletName: 'Marcos', id: 22 }, { walletName: 'Allan', id: 23 }, { walletName: 'Leusa', id: 24 }, { walletName: '@Me', id: 25 }, { walletName: 'Marcos (Carro)', id: 26 }, { walletName: 'Mom', id: 27 }, { walletName: 'Kids', id: 28 }],
    );
  }, []);

  const handleWalletChange = (event) => {
    console.log('handleWalletChange');
    const { value } = event.target;
    console.log(value);
    dispatch(setSelectedWallet(value));
  };

  return (
    <FormControl size="small" sx={{ minWidth: 120 }}>
      <Select
        value={selectedWallet}
        onChange={handleWalletChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {walletList.sort((a, b) => a.walletName.localeCompare(b.walletName))
          .map((e) => (
            <MenuItem key={e.id} value={e.id}>{e.walletName}</MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
