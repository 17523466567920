import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { SignUp } from '../pages/SignUp';
import { Login } from '../pages/Login';
import { NotFound } from '../pages/NotFound';
import { Wallet } from '../pages/Wallet';
import { Stake } from '../pages/Stake';
import { Exchange } from '../pages/Exchange';
import { Widthdraw } from '../pages/Widthdraw';
import { Deposit } from '../pages/Deposit';
import { Settings } from '../pages/Settings';
import { ProcessPayment } from '../pages/ProcessPayment';
import { Stocks } from '../pages/Stocks';
import { Locked } from '../pages/Locked';

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/exchange" element={<Exchange />} />
        <Route path="/stocks" element={<Stocks />} />
        <Route path="/withdraw" element={<Widthdraw />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/process-payment" element={<ProcessPayment />} />
        <Route path="/lockout" element={<Locked />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
