import Button from '@mui/material/Button';
import { useState } from 'react';
import { HeaderLogged } from '../components/HeaderLogged';
import { Footer } from '../components/Footer';

export function Settings() {
  const [apply, setApply] = useState(false);
  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <h1>Settings</h1>
        <p>
          Settings are not available for this device yet.
        </p>
        <br />
        <p>
          Another user wants to push some new configs to this device.
          Do you want to apply the changes?
          <br />
          <br />
          {!apply && <Button variant="contained" color="success" onClick={() => setApply(true)}>Apply</Button>}
          {apply ? 'Okay! Changes accepted! You need to wait for the settings to syncronize.' : ''}
        </p>
      </div>
      <Footer />
    </div>
  );
}
