import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import DepositQR from '../assets/png/deposit.png';
import { Footer } from '../components/Footer';
import { HeaderLogged } from '../components/HeaderLogged';
import { WalletSelection } from '../components/WalletSelection';

export function Deposit() {
  const selectedWallet = useSelector((state) => state.selectedWallet);
  const [amount, setAmount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [depositHistory, setDepositHistory] = useState([]);

  useEffect(() => {
    axios.post(('https://cryptosh11-crypto-cricket.pandlr.com/deposit/get-deposits/'), {
      userId: localStorage.getItem('userId'),
    })
      .then((response) => {
        setDepositHistory(response.data);
      });
  }, [loading]);

  const newDeposit = async () => {
    if (selectedWallet === 22) return alert('Please select a wallet');
    setLoading(true);
    await axios.post('https://cryptosh11-crypto-cricket.pandlr.com/deposit/new/', {
      userId: localStorage.getItem('userId'),
      walletId: selectedWallet,
      depositAmount: amount,
    });
    return setLoading(false);
  };

  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          <h1>Deposit</h1>
          <WalletSelection />
        </Box>
        <p>Deposit USDT to this address on Binance Smart Chain.</p>
        <p>This account currently have a limit of 500 USDT for deposit transactions.</p>
        <Stack justifyContent="center" marginTop={5} spacing={2} direction="row">
          <img alt="Deposit with QR Code" src={DepositQR} />
          <TextField
            id="outlined-multiline-static"
            label="USDT (BSC) | Binance Chain"
            multiline
            rows={7}
            defaultValue="0xa794F593CfFcE0845D9265c466093ee974Dc28f9"
          />
        </Stack>

        <Stack justifyContent="center" marginTop={10} spacing={2} direction="row">
          <TextField
            id="standard-helperText"
            defaultValue={amount}
            onChange={(e) => setAmount(e.target.value)}
            padding={10}
            helperText="Amount (USDT) Maximum 500 USDT"
            variant="standard"
          />
          <Button variant="contained" color="success" onClick={newDeposit}>{loading ? 'Loading...' : 'Deposit'}</Button>
        </Stack>
        <h2>Deposit History</h2>
        <br />
        <table>
          <thead>
            <tr>
              <th>Wallet Name</th>
              <th>Deposit Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {depositHistory.map((e) => (
              <tr key={uuidv4()}>
                <td className="currency">{e.walletName}</td>
                <td className="symbol">
                  $
                  {e.depositAmount}
                </td>
                <td className="price">{e.depositStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {depositHistory.length === 0 && <p>No deposits yet.</p>}
      </div>
      <Footer />
    </div>
  );
}
