import { useNavigate, Link } from 'react-router-dom';
import Logo from '../assets/png/logo2.png';

export function Header() {
  const navigate = useNavigate();
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="login">
          <button
            type="button"
            className="login-btn"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
        </div>
      </div>
    </header>
  );
}
