import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({})('reducer');

export const signUpSlice = createSlice({
  name: 'signUpMode',
  initialState,
  reducers: {
    setSignUpForm: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setSignUpForm } = signUpSlice.actions;
