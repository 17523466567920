import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

export function NotFound() {
  return (
    <div>
      <Header />
      <main className="container">
        <div className="wallet-container">
          <h1>Not Found :(</h1>
        </div>
      </main>
      <Footer />
    </div>
  );
}
